import React, { useEffect } from "react";

const SerialKeyListener = () => {
  useEffect(() => {
    async function connectSerial() {
      if (!("serial" in navigator)) {
        console.error("Web Serial API not supported in this browser.");
        return;
      }
      let port;
      // Try to use already-authorized ports
      const ports = await navigator.serial.getPorts();
      if (ports.length > 0) {
        port = ports[0];
      } else {
        try {
          // This will prompt the user for permission
          port = await navigator.serial.requestPort();
        } catch (error) {
          console.error("Serial port selection was cancelled or failed:", error);
          return;
        }
      }
      try {
        await port.open({ baudRate: 9600 });
        console.log("Serial port opened successfully.");
      } catch (error) {
        console.error("Failed to open serial port:", error);
        return;
      }

      // Convert incoming bytes to text
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();

      async function readLoop() {
        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              console.log("Reader closed.");
              break;
            }
            if (value) {
              for (let char of value) {
                if (char === "a" || char === "b") {
                  simulateKeyPress(char);
                }
              }
            }
          }
        } catch (error) {
          console.error("Error reading from serial port:", error);
        }
      }
      readLoop();
    }

    function simulateKeyPress(key) {
      const keyCode = key.toUpperCase().charCodeAt(0);
      // Create a keydown event
      const keyDownEvent = new KeyboardEvent("keydown", {
        key: key,
        code: `Key${key.toUpperCase()}`,
        keyCode: keyCode,
        which: keyCode,
        bubbles: true,
        cancelable: true,
      });
      // Create a keyup event
      const keyUpEvent = new KeyboardEvent("keyup", {
        key: key,
        code: `Key${key.toUpperCase()}`,
        keyCode: keyCode,
        which: keyCode,
        bubbles: true,
        cancelable: true,
      });

      // Dispatch events to the document
      document.dispatchEvent(keyDownEvent);
      // Dispatch keyup after a short delay
      setTimeout(() => {
        document.dispatchEvent(keyUpEvent);
      }, 10);

      console.log(`Simulated keypress for "${key}"`);
    }

    connectSerial();
  }, []);

  return null; // This component does not render any visible UI
};

export default SerialKeyListener;